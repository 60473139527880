export const Footer = () => {
	return (
		<footer className="p-8 bg-white text-black">
			<div className="flex items-center flex-wrap justify-between gap-2">
				<div>
					<h3 className="font-anticva mb-2">Farmacia Corti</h3>
					<div className="flex space-x-2">
						<a
							href="https://www.iubenda.com/privacy-policy/31159265"
							className="iubenda-nostyle iubenda-noiframe iubenda-embed iubenda-noiframe font-semibold"
							title="Privacy Policy "
						>
							Privacy Policy
						</a>

						<a
							href="https://www.iubenda.com/privacy-policy/31159265/cookie-policy"
							className="iubenda-nostyle iubenda-noiframe iubenda-embed iubenda-noiframe font-semibold"
							title="Cookie Policy"
						>
							Cookie Policy
						</a>
					</div>
				</div>
				<p className="text-sm font-semibold">Copyright © 2024 Farmacia Corti. All rights reserved.</p>
			</div>
		</footer>
	);
};
