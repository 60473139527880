import { createContext } from "react";

type Option<T> = {
	label: string;
	value: T;
};

type SearchVariant = "search" | "searchWithFilters";

export type NavigationLayoutProps = {
	showSearch?: boolean;
	defaultSearchValue?: string;
	debouncedCallback?(search?: string): void | Promise<void>;
} & (
	| {
			searchVariant: Extract<SearchVariant, "search">;
	  }
	| {
			searchVariant: Extract<SearchVariant, "searchWithFilters">;
			options: Array<Option<string>>;
			disabled?: boolean;
	  }
);

export type NavigationContextProps = {
	showSearch?: boolean;
	search?: string;
	onChangeNavigationLayout(payload: NavigationLayoutProps): void;
	navigationLayout: NavigationLayoutProps;
	onChangeText?(text: string): void;
} & (
	| {
			searchVariant: Extract<SearchVariant, "search">;
	  }
	| {
			searchVariant: Extract<SearchVariant, "searchWithFilters">;
			filter?: {
				options: Array<Option<string>>;
				value: string | null;
				onChange?(selectedOption: string): void;
				disabled?: boolean;
			};
	  }
);

export const NavigationContext = createContext<NavigationContextProps | undefined>(undefined);
